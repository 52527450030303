import type {
  ActivableProductInformationId,
  ActivableProductInformationIdTag,
  DimensionTag,
  Product,
} from '@orus.eu/dimensions'
import { memo } from 'react'
import { Badge, type BadgeProps } from '../../../badge/badge.js'

type ProductBadgeProps = {
  /**
   * The product to display the badge for.
   * When not provided, an empty fragment is rendered, as syntactic sugar to avoid
   * conditionally rendering the badge in many places.
   */
  product?: Product
}

export const ProductBadge = memo<ProductBadgeProps>(function ProductBadge({ product }) {
  return product ? <Badge {...badgePropsPerProduct[product].props}>{badgePropsPerProduct[product].label}</Badge> : <></>
})

type ActivableProductInformationIdBadgeProps = {
  /**
   * The tag to display the badge for.
   * When not provided, an empty fragment is rendered, as syntactic sugar to avoid
   * conditionally rendering the badge in many places.
   */
  activableProductInformationId?: ActivableProductInformationId
}

export const ActivableProductInformationIdBadge = memo<ActivableProductInformationIdBadgeProps>(function ProductBadge({
  activableProductInformationId,
}) {
  return activableProductInformationId ? (
    <Badge {...badgePropsPerActivableProductInformationId[activableProductInformationId].props}>
      {badgePropsPerActivableProductInformationId[activableProductInformationId].label}
    </Badge>
  ) : (
    <></>
  )
})

type Tag = DimensionTag | ActivableProductInformationIdTag

type TagBadgeProps = {
  /**
   * The tag to display the badge for.
   * When not provided, an empty fragment is rendered, as syntactic sugar to avoid
   * conditionally rendering the badge in many places.
   */
  tag?: Tag
}

export const TagBadge = memo<TagBadgeProps>(function TagBadge({ tag }) {
  return tag ? <Badge {...tagPropsPerTag[tag].props}>{tagPropsPerTag[tag].label}</Badge> : <></>
})

type BadgeLabelAndVariantProps = { props: { variant: BadgeProps['variant']; dark?: BadgeProps['dark'] }; label: string }

const tagPropsPerTag: Record<Tag, BadgeLabelAndVariantProps> = {
  MRPH: { label: 'MRPH', props: { variant: 'jasmine' } },
  MRPW: { label: 'MRPW', props: { variant: 'jasmine' } },
  RCPH: { label: 'RCPH', props: { variant: 'sky' } },
  MUTA: { label: 'MUTA', props: { variant: 'peach' } },
  RCDA: { label: 'RCDA', props: { variant: 'periwinkle' } },
  CYBER: { label: 'CYBER', props: { variant: 'mindaro' } },
  TDMI: { label: 'TDMI', props: { variant: 'periwinkle' } },
  'ES-RCPH': { label: '🇪🇸 RCPH', props: { variant: 'sky' } },
}

const badgePropsPerProduct: Record<Product, BadgeLabelAndVariantProps> = {
  mrph: tagPropsPerTag.MRPH,
  mrpw: tagPropsPerTag.MRPW,
  rcph: tagPropsPerTag.RCPH,
  muta: tagPropsPerTag.MUTA,
  rcda: tagPropsPerTag.RCDA,
  'es-rcph': tagPropsPerTag['ES-RCPH'],
}

const badgePropsPerActivableProductInformationId: Record<ActivableProductInformationId, BadgeLabelAndVariantProps> = {
  ...badgePropsPerProduct,
  'rc-pro': tagPropsPerTag.RCPH,
  'rcph-cyber': tagPropsPerTag.CYBER,
  'rcph-tdmi': tagPropsPerTag.TDMI,
}
